<template>
  <section class="about" style="background: #FFF; position: relative;">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="tnf-top-banner"
      >
    </v-img>
    <v-container  grid-list-md>
      <v-layout wrap mt-6>
        <v-flex xs12 sm12 md12 lg12 mb-4>
          <h5 class="tnf-qc-yellow">特诺发（中国）</h5>
          <h3 class="tnf-qc-title" >快窗闪换服务<span>Quick Change</span></h3>
          <p class="tnf-qc-topp">带来了欧洲成熟的快速换窗技术，<b style="color:#1166A6;">不用搬家，不用敲墙，不破坏装修</b>。一个周末，全屋旧窗焕新颜，轻松获得高品质德系门窗。</p>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mb-10>
          <v-img
            max-height="552"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_top.jpg"
            contain
          ></v-img>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mb-4>
          <v-layout wrap class="tnf-qc-step">
            <v-flex md1 lg1 class="hidden-sm-and-down"></v-flex>
            <v-flex xs6 sm6 md2 lg2 mb-4 pa-2>
              <v-img
                max-height="180"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_step1.png"
                contain
              ></v-img>
              <h4>1.拆除旧窗</h4>
            </v-flex>
            <v-flex xs6 sm6 md2 lg2 mb-4 pa-2>
              <v-img
                max-height="180"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_step2.png"
                contain
              ></v-img>
              <h4>2.切割旧窗</h4>
            </v-flex>
            <v-flex xs6 sm6 md2 lg2 mb-4 pa-2>
              <v-img
                max-height="180"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_step3.png"
                contain
              ></v-img>
              <h4>3.安装新框</h4>
            </v-flex>
            <v-flex xs6 sm6 md2 lg2 mb-4 pa-2>
              <v-img
                max-height="180"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_step4.png"
                contain
              ></v-img>
              <h4>4.安装盖板</h4>
            </v-flex>
            <v-flex xs6 sm6 md2 lg2 mb-4 pa-2>
              <v-img
                max-height="180"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_step5.png"
                contain
              ></v-img>
              <h4>5.安装窗扇</h4>
            </v-flex>
            <v-flex md1 lg1 class="hidden-sm-and-down"></v-flex>
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout wrap class="tnf-qc-ts">
            <v-flex xs12 sm6 md3 lg3 pa-2 pa-lg-4>
              <v-img
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_ts1.png"
                contain
              >
                <div><img class="tnf-qc-tsi" src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_tsi_1.png"/></div>
                <b>防音</b>
              </v-img>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 pa-2 pa-lg-4>
              <v-img
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_ts2.png"
                contain
              >
                <div><img class="tnf-qc-tsi" src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_tsi_2.png"/></div>
                <b>保护</b>
              </v-img>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 pa-2 pa-lg-4>
              <v-img
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_ts3.png"
                contain
              >
                <div><img class="tnf-qc-tsi" src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_tsi_3.png"/></div>
                <b>快速</b>
              </v-img>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 pa-2 pa-lg-4>
              <v-img
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_ts4.png"
                contain
              >
                <div><img class="tnf-qc-tsi" src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_tsi_4.png"/></div>
                <b>焕彩</b>
              </v-img>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mb-4 mt-10>
          <h3 class="tnf-qc-title" >换窗优势<span>Advantage</span></h3>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6>
          <v-layout wrap class="tnf-qc-ys">
            <v-flex xs2 sm2 md2 lg2>
              <v-img
                height="40"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_ys1.png"
                contain
                position="top 0 right 0"
              >
              </v-img>
            </v-flex>
            <v-flex xs10 sm10 md10 lg10>
              <h5>高效隔音</h5>
              <p>隔音性能最高可达42db，有效阻隔室外噪音，创造安静舒适的生活环境。</p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6>
          <v-layout wrap class="tnf-qc-ys">
            <v-flex xs2 sm2 md2 lg2>
              <v-img
                height="40"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_ys2.png"
                contain
                position="top 0 right 0"
              >
              </v-img>
            </v-flex>
            <v-flex xs10 sm10 md10 lg10>
              <h5>个性美观</h5>
              <p>Emotional Cover™是特诺发独有的色彩覆膜配色系统，赋能门窗表面全新的视觉与触觉效果，满足门窗耐候性和永久性需求。</p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6>
          <v-layout wrap class="tnf-qc-ys">
            <v-flex xs2 sm2 md2 lg2>
              <v-img
                height="40"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_ys3.png"
                contain
                position="top 0 right 0"
              >
              </v-img>
            </v-flex>
            <v-flex xs10 sm10 md10 lg10>
              <h5>保持原有</h5>
              <p>采用欧洲最新快速换窗技术，不破坏现有窗套和窗台板，保证现有门窗洞口装饰不损坏。</p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6>
          <v-layout wrap class="tnf-qc-ys">
            <v-flex xs2 sm2 md2 lg2>
              <v-img
                height="40"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_ys4.png"
                contain
                position="top 0 right 0"
              >
              </v-img>
            </v-flex>
            <v-flex xs10 sm10 md10 lg10>
              <h5>节能保温</h5>
              <p>热传系列最低达1.6W/m².k,高效节约制冷/采暖费用，节约家庭开支，实现低碳生活。</p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6>
          <v-layout wrap class="tnf-qc-ys">
            <v-flex xs2 sm2 md2 lg2>
              <v-img
                height="40"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_ys5.png"
                contain
                position="top 0 right 0"
              >
              </v-img>
            </v-flex>
            <v-flex xs10 sm10 md10 lg10>
              <h5>安全防盗</h5>
              <p>采用进口内开内倒五金件，启闭次数大于2.5万次，有效保证门窗寿命，提升安全防盗性能告别丑陋防盗窗。</p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mb-4 mt-10>
          <h3 class="tnf-qc-title" >服务流程<span>Service Process</span></h3>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12>
          <v-layout wrap>
            <v-flex xs12 sm6 md3 lg3 pr-lg-4 pr-md-4 pr-sm-4 mb-4>
              <v-layout wrap pa-4 class="tnf-qc-lc">
                <v-flex xs12 sm12 md12 lg12>
                  <v-img
                    height="40"
                    src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_lc1.png"
                    contain
                    position="center"
                  >
                  </v-img>
                </v-flex>
                <h4>上门测量</h4>
                <p>特诺发快窗闪换服务基于测量师精确测量门窗面积，评估家庭环境等状况。</p>
                <h4>签订合同</h4>
                <p>根据测量结果及消费需求，给客户推荐合适的产品，签订合同。</p>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 pr-lg-4 pr-md-4 pr-sm-4 pl-lg-4 pl-md-4 pl-sm-4 mb-4>
              <v-layout wrap pa-4 class="tnf-qc-lc">
                <v-flex xs12 sm12 md12 lg12>
                  <v-img
                    height="40"
                    src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_lc2.png"
                    contain
                    position="center"
                  >
                  </v-img>
                </v-flex>
                <h4>快速定制</h4>
                <p>根据客户需求快速制作相应产品。</p>
                <h4>预约上门</h4>
                <p>消费者根据自身情况，预约上门快速换窗。</p>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 pr-lg-4 pr-md-4 pr-sm-4 pl-lg-4 pl-md-4 pl-sm-4 mb-4>
              <v-layout wrap pa-4 class="tnf-qc-lc">
                <v-flex xs12 sm12 md12 lg12>
                  <v-img
                    height="40"
                    src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_lc3.png"
                    contain
                    position="center"
                  >
                  </v-img>
                </v-flex>
                <h4>家装保护</h4>
                <p>施工区域范围内地面、墙面贴上保护层，小心搬离家具。</p>
                <h4>快窗闪换</h4>
                <p>用专业工具和工艺承诺做到不破坏装修，3小时快速换窗。</p>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 pl-lg-4 pl-md-4 pl-sm-4 mb-4>
              <v-layout wrap pa-4 class="tnf-qc-lc">
                <v-flex xs12 sm12 md12 lg12>
                  <v-img
                    height="40"
                    src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_lc4.png"
                    contain
                    position="center"
                  >
                  </v-img>
                </v-flex>
                <h4>清洁归位</h4>
                <p>用吸尘器吸取施工中产生的灰尘，清理垃圾，将家具归位，带离垃圾。</p>
                <h4>交付验收</h4>
                <p>用户检查窗户开启是否灵活、有无明显刮痕，签收验收单并对服务做出评价。</p>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mb-4 mt-10>
          <h3 class="tnf-qc-title" >服务理念<span>Service Cincept</span></h3>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mb-10>
          <v-layout wrap>
            <v-flex xs12 sm12 md4 lg4 xl3>
              <v-img
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qc_ln.jpg"
                contain
              >
              </v-img>
            </v-flex>
            <v-flex xs12 sm12 md8 lg8 xl9 pl-md-10 pl-lg-10 class="tnf-qc-ln">
              <h4>获取需求<span>幼生期</span></h4>
              <p>我们会悉心了解您的每一个需求，如同呵护幼虫的成长一般，为您订制最适合的解决方案。</p>
              <h4>上门测量<span>成虫期</span></h4>
              <p>我们会根据成熟的解决方案，上门测量，以求精准实现服务目标。并送上我们的吉祥物可爱的毛虫，共同期待化蝶时刻的到来。</p>
              <h4>快窗制作<span>结茧期</span></h4>
              <p>工厂会根据测量数据，以最快的速度订制产品，如同孕育蜕变的结茧时刻。坚实的产品宛如坚实的保护，为全家的安全健康保驾护航。</p>
              <h4>闪换服务<span>化蝶期</span></h4>
              <p>我们将用3小时，一只蝴蝶破茧而出的时间，采用欧洲快速换窗技术，不破坏现有窗套和窗台板保证现有门窗洞口装饰不损坏。</p>
              <h4>完美绽放</h4>
              <p>当一起完成，我们将共同见证完美绽放后的焕新生活，工作人员会送上一对翅膀，为可爱的吉祥物毛虫插上美丽的翅膀，象征新的开始。</p>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 11
  }),
  created () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '../css/quick_change.css';
</style>
